"use client";

// components
import { SplashScreen } from "src/components/loading-screen";

// ----------------------------------------------------------------------

export default function Loading() {
	return <SplashScreen />;
}
