// ----------------------------------------------------------------------

export function getRatio(ratio = "1/1") {
	return {
		"4/3": "calc(100% / 4 * 3)",
		"3/4": "calc(100% / 3 * 4)",
		"6/4": "calc(100% / 6 * 4)",
		"4/6": "calc(100% / 4 * 6)",
		"16/9": "calc(100% / 16 * 9)",
		"9/16": "calc(100% / 9 * 16)",
		"21/9": "calc(100% / 21 * 9)",
		"9/21": "calc(100% / 9 * 21)",
		"1/1": "100%",
	}[ratio];
}
